import dayjs from 'dayjs';

export const makeUpperCase = (text: string): string => {
  return text?.toUpperCase();
};

export const formatDate = (date: string | number, format?: string): string => {
  if (format) return dayjs(date).format(format);
  return dayjs(date).format('YYYY/MM/DD, hh : mm a');
};

export const getTimestampInMillisecond = (date: string) => {
  return dayjs(date).valueOf();
};

export const changeNumberToString = (number: number) => {
  return number.toString();
};
