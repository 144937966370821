import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { CircularProgress, Modal } from '@material-ui/core';

import { colors } from '../utils/colors';
import { spacing } from '../utils/spacing';

interface CardProps {
  flexDirection?: string;
  width?: string;
  justifyContent?: string;
  alignItems?: string;
  gap?: string;
  borderRadius?: string;
  padding?: string;
}

interface FlexProps {
  flexDirection?: string;
  justifyContent?: string;
  alignItems?: string;
  gap?: string;
}

interface ImageProps {
  objectFit?: string;
}

interface TextProps {
  fontSize?: string;
  textAlign?: string;
  margin?: string;
  color?: string;
  fontWeight?: string;
}

interface GridProps {
  placeItems?: string;
  gridTemplate?: string;
  gridGap?: string;
}

interface StyledLinkProps {
  textDecoration?: string;
  color?: string;
}

interface DivProps {
  background?: string;
  padding?: string;
  margin?: string;
  position?: string;
}

export const Container = styled.div`
  background: ${(props: DivProps) => props.background ?? 'white'};
  padding: ${(props: DivProps) => props.padding ?? '5px'};
`;

export const Card = styled.div`
  display: flex;
  background-color: ${colors.white};
  flex-direction: ${(props: CardProps) => props.flexDirection ?? 'column'};
  height: auto;
  width: ${(props: CardProps) => props.width ?? '380px'};
  gap: ${(props: CardProps) => props.gap ?? '12px'};
  padding: ${(props: CardProps) => props.padding ?? '12px 40px'};
  border-radius: ${(props: CardProps) => props.borderRadius ?? '25px '};
  justify-content: ${(props: CardProps) => props.justifyContent ?? ''};
  align-items: ${(props: CardProps) => props.alignItems ?? ''};
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props: FlexProps) => props.flexDirection ?? 'column'};
  justify-content: ${(props: FlexProps) => props.justifyContent};
  align-items: ${(props: FlexProps) => props.alignItems};
  gap: ${(props: FlexProps) => props.gap};
`;

export const Image = styled.img`
  object-fit: ${(props: ImageProps) => props.objectFit ?? 'cover'};
`;

export const Text = styled.p`
  font-size: ${(props: TextProps) => props.fontSize ?? spacing.xl};
  text-align: ${(props) => props.textAlign ?? 'center'};
  color: ${(props) => props.color ?? colors.black};
  margin: ${(props) => props.margin ?? '0'};
  font-weight: ${(props) => props.fontWeight ?? 'normal'};
`;

export const Grid = styled.div`
  display: grid;
  place-items: ${(props: GridProps) => props.placeItems};
  grid-template: ${(props: GridProps) => props.gridTemplate};
  grid-gap: ${(props: GridProps) => props.gridGap};
`;

export const StyledLink = styled(Link)`
  text-decoration: ${(props: StyledLinkProps) =>
    props.textDecoration ?? 'none'};
  color: ${(props: StyledLinkProps) => props.color ?? colors.stratosfyColor};
`;

export const CircularLoader = styled(CircularProgress)`
  && {
    color: ${(props) => props.color ?? colors.white};
  }
`;

export const StyledModal = styled(Modal)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
