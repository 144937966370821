import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../utils/colors';
import PrimaryButton from '../reusableComponents/PrimaryButton';

const useStyles = makeStyles({
    root: {
        width: '12em',
        color: `${colors.white}`,
        position: 'fixed',
        top: 'auto',
        right: '20px',
        left: 'auto',
        bottom: '20px',
    },
});

export default function FloatingActionButtons(props: any) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <PrimaryButton onClick={props.onclick}>
                {props.children}
            </PrimaryButton>
        </div>
    );
}
