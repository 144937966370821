import HistoryIcon from 'mdi-react/HistoryIcon';
// import ReceiptIcon from 'mdi-react/ReceiptIcon';
// import HumanIcon from 'mdi-react/HumanMaleIcon';
import CalendarIcon from 'mdi-react/CalendarAddIcon';
import PlaylistCheckIcon from 'mdi-react/PlaylistCheckIcon';
import AccountChildOutlineIcon from 'mdi-react/AccountChildOutlineIcon';

import Jobs from '../components/jobs/Jobs';
import Shifts from '../components/shifts/Shifts';
import SubCrews from '../components/SubCrews/SubCrews';
// import Invoices from '../components/invoices/Invoices';
// import Customers from '../components/customers/Customers';
import JobsHistory from '../components/jobHistory/JobsHistory';

export const privateRoutes = [
  { path: '/jobs', name: 'Jobs', component: Jobs, Icon: PlaylistCheckIcon },
  {
    path: '/jobs-history',
    name: 'Jobs History',
    component: JobsHistory,
    Icon: HistoryIcon,
  },
  {
    path: '/crews',
    name: 'Crews',
    component: SubCrews,
    Icon: AccountChildOutlineIcon,
  },
  // {
  //   path: '/customers',
  //   name: 'Customers',
  //   component: Customers,
  //   Icon: HumanIcon,
  // },
  {
    path: '/shifts',
    name: 'Shifts',
    component: Shifts,
    Icon: CalendarIcon,
  },
  // {
  //   path: '/invoices',
  //   name: 'Invoices',
  //   component: Invoices,
  //   Icon: ReceiptIcon,
  // },
];
