import React, { FormEvent, FunctionComponent } from 'react';

import styled from 'styled-components';
import { Button } from '@material-ui/core';

interface ButtonProps {
  onClick: (event: any) => void;
  type?: 'submit' | 'button' | 'reset';
  onSubmit?: (event: FormEvent<HTMLFormElement | HTMLButtonElement>) => void;
}

const PrimaryButton: FunctionComponent<ButtonProps> = ({
  children,
  onClick,
  type,
  onSubmit,
}) => {
  return (
    <PButton onClick={onClick} type={type} onSubmit={onSubmit}>
      {children}
    </PButton>
  );
};

export default PrimaryButton;

const PButton = styled(Button)`
  && {
    background-image: linear-gradient(to right, #00afee, #006eb9);
    text-decoration: none;
  }
`;
