import { auth, functions } from '.';
import { getCrewById } from './crew';

export function checkIfFirebaseAuth(setAuthenticatedUser: (user: {}) => void) {
  auth.onAuthStateChanged(async (user) => {
    if (!user) {
      setAuthenticatedUser({});
      return;
    }
    const { uid } = user;

    const crew = await getCrewById(uid);

    if (crew?.crewType !== 'Org') {
      setAuthenticatedUser({});
      return;
    }
    setAuthenticatedUser(crew);
  });
}

export function logInWithId(crewId: string) {
  const logInById = functions.httpsCallable(
    'advanceLogin-advanceLogin-loginById'
  );

  return logInById({ crewId });
}

export function loginUser(data: any) {
  var { token } = data;
  return auth.signInWithCustomToken(token);
}

export function logOut() {
  return auth.signOut();
}

export async function logInWithEmailAndPassword(params: LoginCred) {
  const { email, password } = params;
  const resp = await auth.signInWithEmailAndPassword(email, password);

  const uid = resp.user?.uid;
  if (uid) {
    return getCrewById(uid);
  }
  return {};
}

interface LoginCred {
  email: string;
  password: string;
}
