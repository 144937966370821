import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  Grid,
  Container,
  FlexContainer,
  CircularLoader,
} from '../../generics/styled';

import JobItem from '../jobs/JobItem';
import { colors } from '../../utils/colors';
import { spacing } from '../../utils/spacing';
import { getCompletedJobs } from '../../api/jobs';

export default function JobsHistory() {
  const [jobsHistory, setJobsHistory] = useState<Array<any>>([]);

  const getJobsHistory = async () => {
    try {
      const completedJobs = await getCompletedJobs();
      setJobsHistory(completedJobs);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getJobsHistory();
  }, []);

  if (!jobsHistory?.length)
    return (
      <CenterLayout justifyContent="center" alignItems="center">
        <CircularLoader color="primary" />
      </CenterLayout>
    );

  return (
    <Container background={colors.background} padding={spacing.xl}>
      <Grid
        gridTemplate="auto / repeat(auto-fill, minmax(440px, 1fr))"
        gridGap={`${spacing.l} 0`}
      >
        {jobsHistory?.map((job: any) => (
          <JobItem key={job?.key} job={job} onButtonClick={() => {}} />
        ))}
      </Grid>
    </Container>
  );
}

const CenterLayout = styled(FlexContainer)`
  background: ${colors.background};
`;
