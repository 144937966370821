import React, { useContext } from "react";

import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import Login from "../components/Login";
import { privateRoutes } from "./routes";
import { colors } from "../utils/colors";
import { Grid } from "../generics/styled";
import { AuthContext } from "../contexts/authContext";
import styled from "styled-components";

const GuestRoutes = () => {
  const auth = useContext(AuthContext);

  const location = useLocation();

  const { pathname } = location;
  const { authenticatedUser } = auth;

  if (!authenticatedUser) {
    return <p>loading in guest....</p>;
  }

  if (authenticatedUser?.crewType) {
    const paths = privateRoutes.map((route) => route.path);
    const setOfPaths = new Set(paths);
    if (!setOfPaths.has(pathname)) {
      return <Redirect to="/jobs" />;
    }
    return <Redirect to={pathname} />;
  }

  return (
    <GuestGrid placeItems="center">
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route path="/signup">
          <span>sign up</span>
        </Route>
        <Redirect to="/login" />
      </Switch>
    </GuestGrid>
  );
};

export default GuestRoutes;

const GuestGrid = styled(Grid)`
  background-color: ${colors.stratosfyColor};
  height: 100vh;
`;
