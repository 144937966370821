import React, { createContext, FunctionComponent, useState } from "react";

const defaultValue = {
  authenticatedUser: {
    crewType: null,
    imageUrl: "",
    name: "",
  },
  setAuthenticatedUser: (data: any) => {},
};

export const AuthContext = createContext(defaultValue);

const AuthenticationContext: FunctionComponent = ({ children }) => {
  const [authenticatedUser, setAuthenticatedUser] = useState<any>();

  return (
    <AuthContext.Provider value={{ authenticatedUser, setAuthenticatedUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthenticationContext;
