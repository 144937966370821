import React, { MouseEvent, SyntheticEvent, useContext, useState } from 'react';

import styled from 'styled-components';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  AppBar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
} from '@material-ui/core';

import { logOut } from '../api/auth';
import { logo } from '../utils/misc';
import { privateRoutes } from './routes';
import { colors } from '../utils/colors';
import { Grid, Image } from '../generics/styled';
import { spacing, width } from '../utils/spacing';
import { AuthContext } from '../contexts/authContext';

const PrivateRoutes = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const auth = useContext(AuthContext);

  const location = useLocation();
  const history = useHistory();

  const currentPath = location.pathname;

  const { authenticatedUser } = auth;

  const isMenuOpen = !!anchorEl;

  function handleProfileClick(event: MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  async function handleLogOutButton() {
    try {
      await logOut();
    } catch (error) {
      console.error('@@Error', error);
    }
  }

  function onImageError(event: SyntheticEvent<HTMLImageElement, Event>) {
    event.currentTarget.src =
      'https://iupac.org/wp-content/uploads/2018/05/default-avatar.png';
  }

  function handleDrawerItemClick(path: string) {
    history.push(path);
  }

  return (
    <UserGrid gridTemplate="auto 1fr/auto 1fr">
      <StyledAppBar position="sticky">
        <StyledToolbar>
          <Image src={logo} width={128} alt="Logo" />
          <IconButton onClick={handleProfileClick}>
            <ProfilePic
              height={30}
              width={30}
              src={authenticatedUser.imageUrl}
              alt="Profile"
              onError={onImageError}
            />
          </IconButton>
          <Menu
            open={isMenuOpen}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            id="primary-search-account-menu"
            anchorEl={anchorEl}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleLogOutButton}>Log out</MenuItem>
          </Menu>
        </StyledToolbar>
      </StyledAppBar>
      <StyledDrawer variant="permanent">
        <div style={{ overflow: 'auto' }}>
          <List>
            {privateRoutes.map((route) => {
              const { Icon } = route;
              return (
                <ListItem
                  selected={route.path === currentPath}
                  button
                  key={route.path}
                  onClick={() => handleDrawerItemClick(route.path)}
                >
                  <ColoredListIcon>
                    <Icon />
                  </ColoredListIcon>
                  <ListItemText
                    primary={route.name}
                    style={{ color: 'white' }}
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
      </StyledDrawer>
      <Switch>
        {privateRoutes.map((route) => {
          const Component = route.component;
          return (
            <Route exact path={route.path} key={route.path}>
              <Component />
            </Route>
          );
        })}
        <Redirect to="/jobs" />
      </Switch>
    </UserGrid>
  );
};

export default PrivateRoutes;

const StyledAppBar = styled(AppBar)`
  && {
    background-color: ${colors.white};
    height: ${spacing.xxxxl};
    grid-column: 1/4;
  }
`;

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
`;

const ProfilePic = styled.img`
  border-radius: 50%;
`;

const StyledDrawer = styled(Drawer)`
  && {
    width: ${width.sm};
    flex-shrink: 0;
    .MuiDrawer-paperAnchorLeft {
      width: ${width.sm};
      top: unset;
      background: ${colors.stratosfyColor};
    }
  }
`;

const UserGrid = styled(Grid)`
  height: 100vh;
`;

const ColoredListIcon = styled(ListItemIcon)`
  && {
    color: ${colors.white};
  }
`;
