export const colors = {
    stratosfyColor: "#29abe2",
    background:"#f2ecec",
    white: "#ffffff",
    black: "#000000",
    darkblue:"#006EB9",
    green:"#1e6309",
    red:"#c40505",
    lightText:"#a0a0a0",
};
