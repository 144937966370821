import { auth, firestore } from './index';

const jobsCollection = firestore.collection('jobs');

export async function getJobs(): Promise<Array<Map<any, any>>> {
  const uid = auth.currentUser?.uid;
  const snapshot = await jobsCollection
    .where('providerUids', 'array-contains', uid)
    .where('status', 'in', ['started', 'assigned'])
    .orderBy('createdDate', 'desc')
    .get();
  const jobs = snapshot.docs.map((doc: any) => doc.data());
  return jobs;
}

export async function getCompletedJobs(): Promise<any> {
  const uid = auth.currentUser?.uid;
  const snapshot = await jobsCollection
    .where('providerUids', 'array-contains', uid)
    .where('status', '==', 'completed')
    .orderBy('createdDate', 'desc')
    .get();
  const completedJobs = snapshot.docs.map((doc: any) => doc.data());
  return completedJobs;
}

export const reassignJobToSubCrews = (jobKey: string, data: any) => {
  return jobsCollection.doc(jobKey).update({ ...data });
};
