import React from "react";

import { BrowserRouter as Router } from "react-router-dom";

import "./App.css";
import Routes from "./routes";
import AuthenticationContext from "./contexts/authContext";

function App() {
  return (
    <AuthenticationContext>
      <Router>
        <Routes />
      </Router>
    </AuthenticationContext>
  );
}

export default App;
