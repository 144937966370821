import { auth, firestore } from './index';

import { Shift } from '../types/shifts';

const customersCollection = firestore.collection('customers');

export const getShiftsByCompanyKey = async (companyKey: string) => {
  const res = await customersCollection
    .doc(companyKey)
    .collection('shifts')
    .get();
  const shifts = res.docs.map((doc: any) => doc.data());
  return shifts;
};

export const addShift = (companyKey: string, shift: Shift) => {
  const ref = customersCollection.doc(companyKey).collection('shifts').doc();

  const { id } = ref;
  const updatedShift = { ...shift, id, companyKey };

  ref.set(updatedShift);

  return updatedShift;
};

export const updateShift = (companyKey: string, updatedShift: Shift) => {
  return customersCollection
    .doc(companyKey)
    .collection('shifts')
    .doc(updatedShift.id)
    .update(updatedShift);
};

export const deleteShift = (companyKey: string, shiftId: string) => {
  return customersCollection
    .doc(companyKey)
    .collection('shifts')
    .doc(shiftId)
    .delete();
};
