import React, { SyntheticEvent } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function SnackBar(props: SnackbarProps) {
  const classes = useStyles();
  const { open, handleToggle, message, severity } = props;

  const handleClose = (event: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    handleToggle();
  };

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

interface SnackbarProps {
  open: boolean;
  handleToggle: () => void;
  message: string;
  severity: 'success' | 'info' | 'warning' | 'error';
}
