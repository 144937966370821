import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';

import dayjs from 'dayjs';

import { colors } from '../../utils/colors';
import { getCrewById } from '../../api/crew';
import { getProperty } from '../../api/property';
import { Card, Text } from '../../generics/styled';
import { spacing, width } from '../../utils/spacing';
import DetailTile from '../reusableComponents/DetailTile';
import PrimaryButton from '../reusableComponents/PrimaryButton';
import {
  makeUpperCase,
  formatDate,
  changeNumberToString,
} from '../../utils/macros';

interface JobProps {
  job: any;
  onButtonClick: () => void;
}

export default function JobItem(props: JobProps) {
  const { job, onButtonClick } = props;

  const [propertyData, setPropertyData] = useState<any[]>([]);
  const [crews, setCrews] = useState<any[]>([]);

  const getPropertyData = async () => {
    const properties = job?.propertyKeys?.map((propertyKey: string) => {
      return getProperty(job?.companyKey, propertyKey);
    });
    const propertyData = await Promise.all(properties);
    setPropertyData(propertyData);
  };

  const mapPropertyData = (field: string) => {
    if (propertyData.length) {
      const propertyFields = propertyData.map(
        (property: any) => property[field]
      );
      return propertyFields.join(',');
    }
    return '-';
  };

  const getTimeSpent = () => {
    if (!job?.startedDate || !job?.endDate) return;

    const endedDate = dayjs(job?.endDate);
    let minutes = endedDate.diff(job?.startedDate, 'minute');

    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    const days = Math.floor(hours / 24);
    hours = hours % 24;

    const timeSpentDays = days ? `${days} Days , ` : '';
    const timeSpentHours = hours ? `${hours} Hours , ` : '';
    const timeSpentMin = minutes ? `${minutes} Min ` : '';

    const timeSpent = timeSpentDays + timeSpentHours + timeSpentMin;

    return <DetailTile title="Time Spent" content={timeSpent} />;
  };

  const getCrews = async () => {
    try {
      if (job?.reassignCrew) {
        const crews = job?.reassignCrew?.map((key: string) => getCrewById(key));
        const crewsData = await Promise.all(crews);
        setCrews(crewsData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCrewsData = (field: string) => {
    if (crews.length) {
      const crewsData = crews.map((crew: any) => crew[field]);
      return crewsData.join(',');
    }
    return '-';
  };

  const getActivitiesLength = () => {
    return Object.keys(job?.activities ?? {}).length.toString();
  };

  const renderButtonLabel = () => {
    if (job?.status !== 'completed') {
      return 'Reassign Job';
    }
    return 'Completed';
  };

  useEffect(() => {
    getPropertyData();
    getCrews();
  }, [job]);

  const renderJobType = () => {
    return (
      <>
        {job?.noTouch ? (
          <DetailTile
            title="Job Type"
            content="No Touch"
            contentColor={colors.stratosfyColor}
          />
        ) : null}
        {job?.continuousJob ? (
          <DetailTile
            title="Continuos Job"
            content="Yes"
            contentColor={colors.stratosfyColor}
          />
        ) : null}
      </>
    );
  };

  const renderServiceTypeFields = () => {
    if (job?.serviceType === 'markerbased') {
      return (
        <>
          <DetailTile
            title="Total Markers"
            content={changeNumberToString(job?.allMarkers?.length ?? 0)}
          />
          <DetailTile
            title="Served Markers"
            content={changeNumberToString(job?.servedMarkers?.length ?? 0)}
          />
        </>
      );
    }
    return (
      <>
        <DetailTile
          title="Total Routes"
          content={changeNumberToString(job?.allRoutes?.length ?? 0)}
        />
        <DetailTile
          title="Served Routes"
          content={changeNumberToString(job?.servedRoutes?.length ?? 0)}
        />
      </>
    );
  };

  return (
    <Card key={job?.key} width={width.l} padding={spacing.xl}>
      <Typography variant="h5" align="center" noWrap>
        {job?.name}
      </Typography>
      <DetailTile
        title="STATUS : "
        justifyContent="center"
        content={makeUpperCase(job?.status)}
        contentColor={colors.stratosfyColor}
      />
      {job?.createdDate && (
        <DetailTile title="Created at" content={formatDate(job?.createdDate)} />
      )}
      {job?.activities?.length > 0 && (
        <DetailTile
          justifyContent="space-between"
          title="Activities on Job"
          content={getActivitiesLength()}
        />
      )}
      {job?.propertyKeys?.length > 0 && (
        <DetailTile title="Property" content={mapPropertyData('name')} />
      )}
      {job?.propertyKeys?.length > 0 && (
        <DetailTile
          title="Property Address "
          content={mapPropertyData('address')}
        />
      )}
      <DetailTile title="Crews Name" content={getCrewsData('name')} />
      {job?.status === 'completed' && job?.endDate ? (
        <DetailTile title="Completed At" content={formatDate(job.endDate)} />
      ) : null}
      {job?.startedDate && (
        <DetailTile title="Started At" content={formatDate(job?.startedDate)} />
      )}
      {renderServiceTypeFields()}

      {job?.serviceType && (
        <DetailTile title="Service Type" content={job?.serviceType} />
      )}
      {getTimeSpent()}
      {renderJobType()}

      {job?.futureAssignmentData?.date && (
        <DetailTile
          title="Schedules At"
          content={formatDate(
            job?.futureAssignmentData?.date,
            'MMM DD,YYYY hh:mm A'
          )}
        />
      )}

      <div style={{ marginTop: 'auto' }}></div>
      <PrimaryButton onClick={onButtonClick}>
        <Text color={colors.white}>{renderButtonLabel()}</Text>
      </PrimaryButton>
    </Card>
  );
}
