import { firestore } from './index';
const customersCollection = firestore.collection('customers');

export async function getProperty(
    companyKey: string,
    key: string,
): Promise<any> {
    const snapshot = await customersCollection
        .doc(companyKey)
        .collection('properties')
        .doc(key)
        .get();
    return snapshot.data();
};
