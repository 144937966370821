import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from 'react';

import styled from 'styled-components';
import DateFnsUtils from '@date-io/dayjs';
import { Button, makeStyles } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import {
  Modal,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import JobItem from './JobItem';
import { colors } from '../../utils/colors';
import { spacing } from '../../utils/spacing';
import { getTimestampInMillisecond } from '../../utils/macros';
import PrimaryButton from '../reusableComponents/PrimaryButton';
import {
  Grid,
  Card,
  Text,
  Container,
  FlexContainer,
  CircularLoader,
} from '../../generics/styled';

import { getSubCrews } from '../../api/crew';
import { AuthContext } from '../../contexts/authContext';
import { getJobs, reassignJobToSubCrews } from '../../api/jobs';

const useStyles = makeStyles({
  root: {
    background: `${colors.background}`,
    padding: `${spacing.xxl} ${spacing.xl}`,
  },
  grid: {
    margin: `${spacing.xxl} 0`,
  },
  modal: {
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formControl: {
    width: '100%',
  },
});

const Jobs: FunctionComponent = () => {
  const classes = useStyles();
  const [data, setData] = useState<Array<Object>>([]);
  const [modal, showModal] = useState(false);
  const [assignedCrew, setAssignedCrew] = useState('');
  const [assignedDate, setAssignedDate] = useState<any>(new Date());
  const [subCrews, setSubCrews] = useState<Array<Map<any, any>>>([]);
  const [note, setNote] = useState(null);
  const [selectedJobKey, setSelectedJobKey] = useState<string>('');
  const [isReassigning, setIsReassigning] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const auth: any = useContext(AuthContext);

  const handleModelOpen = (job: any) => {
    const assignedCrew = job?.reassignCrew ? job?.reassignCrew[0] : [];
    mapAssignedCrews(assignedCrew);
    mapAssignedDate(job?.futureAssignmentData?.date);
    setSelectedJobKey(job?.key);
    showModal(true);
    setNote(job?.crewAdminNote ?? '');
  };

  const handleModalClose = () => {
    showModal(false);
  };

  const handleAssignedCrewChange = (event: any) => {
    setAssignedCrew(event.target.value);
  };

  const getJobsData = async () => {
    setLoading(true);
    const newData: Array<Object> = await getJobs();
    setData(newData);
    setLoading(false);
  };

  const mapSubCrews = async () => {
    const key = auth.authenticatedUser.companyKey;
    const data = await getSubCrews(key);
    setSubCrews(data);
  };

  useEffect(() => {
    getJobsData();
    mapSubCrews();
  }, []);

  const handleJobUpdateInUI = (updatedData: any, selectedJobKey: string) => {
    const updateJobs = [...data];
    const updateIndex = updateJobs.findIndex(
      (job: any) => job.key === selectedJobKey
    );
    updateJobs[updateIndex] = { ...updateJobs[updateIndex], ...updatedData };
    setData(updateJobs);
  };

  const handleReassignButton = async () => {
    if (!assignedCrew) {
      alert('Please select one crew.');
      return;
    }
    setIsReassigning(true);

    let data: any = {
      reassignCrew: [assignedCrew],
      crewAdminNote: note ?? null,
      futureAssignmentData: {},
    };
    if (assignedDate) {
      data.futureAssignmentData['assignedTo'] = assignedCrew;
      data.futureAssignmentData['date'] = getTimestampInMillisecond(
        assignedDate
      );
    }

    try {
      await reassignJobToSubCrews(selectedJobKey, data);
      handleJobUpdateInUI(data, selectedJobKey);
    } catch (error) {
      console.error({ error });
    }
    handleModalClose();
    setIsReassigning(false);
  };

  const handleNoteChange = (event: any) => {
    setNote(event.target.value);
  };

  const mapAssignedCrews = (assignedCrewName: string): void => {
    setAssignedCrew(assignedCrewName);
  };

  const mapAssignedDate = (assignedDate: any): void => {
    setAssignedDate(assignedDate ?? new Date());
  };

  return loading ? (
    <CenterLayout justifyContent="center" alignItems="center">
      <CircularLoader color="primary" />
    </CenterLayout>
  ) : (
    <Container background={colors.background} padding={spacing.xl}>
      {data.length ? (
        <Grid
          gridTemplate="auto / repeat(auto-fill, minmax(440px, 1fr))"
          gridGap={`${spacing.l} 0`}
        >
          {data?.map((job: any) => (
            <JobItem
              key={job?.key}
              job={job}
              onButtonClick={() => handleModelOpen(job)}
            />
          ))}
        </Grid>
      ) : (
        <Text fontSize="15">No jobs assigned to you currently!</Text>
      )}
      <Modal open={modal} className={classes.modal}>
        <Card
          justifyContent="center"
          alignItems="center"
          gap={spacing.xxxl}
          padding={spacing.xxxl}
        >
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">
              Choose Crew
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={assignedCrew}
              label="Choose Crew"
              onChange={handleAssignedCrewChange}
            >
              {subCrews.map((subCrew: any) => (
                <MenuItem key={subCrew.crewId} value={subCrew.uid}>
                  {subCrew.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              inputVariant="outlined"
              className={classes.formControl}
              label="Choose Date"
              value={assignedDate}
              format="YYYY/MM/DD hh:mm A"
              onChange={(date) => {
                setAssignedDate(date);
              }}
            />
          </MuiPickersUtilsProvider>
          <TextField
            variant="outlined"
            fullWidth
            label="Notes"
            value={note}
            onChange={handleNoteChange}
          />
          <FlexContainer flexDirection="row">
            <Button onClick={handleModalClose}>Cancel</Button>
            <PrimaryButton onClick={handleReassignButton}>
              {isReassigning ? (
                <CircularLoader size="2rem" />
              ) : (
                <Text color={colors.white}>Reassign</Text>
              )}
            </PrimaryButton>
          </FlexContainer>
        </Card>
      </Modal>
    </Container>
  );
};

export default Jobs;

const CenterLayout = styled(FlexContainer)`
  background: ${colors.background};
`;
