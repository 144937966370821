import React, { useState, useEffect, ChangeEvent } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import DateFnsUtils from '@date-io/dayjs';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Button, TextField, FormControlLabel, Switch } from '@material-ui/core';

import { colors } from '../../utils/colors';
import { spacing } from '../../utils/spacing';
import PrimaryButton from '../reusableComponents/PrimaryButton';
import {
  Card,
  FlexContainer,
  CircularLoader,
  Text,
} from '../../generics/styled';

import { Shift } from '../../types/shifts';

interface ShiftItemProps {
  shift: Shift;
  handleNegativeAction: () => void;
  handlePositiveAction: (editedShift: Shift) => void;
  isLoading?: boolean;
}

export default function ShiftItem(props: ShiftItemProps) {
  const {
    shift,
    handleNegativeAction,
    handlePositiveAction,
    isLoading,
  } = props;

  const mapShiftTime = (time: string) => {
    if (time) {
      return dayjs(time, 'HH:mm');
    }
    return dayjs();
  };

  const [currentShift, setCurrentShift] = useState<Shift>(shift);
  const [startTime, setStartTime] = useState<Dayjs | null>(
    mapShiftTime(shift.startTime)
  );
  const [endTime, setEndTime] = useState<Dayjs | null>(
    mapShiftTime(shift.endTime)
  );

  const handleFieldChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    setCurrentShift({ ...currentShift, [field]: event?.target?.value });
  };

  //TODO make a interface for field types
  const handleRadioChange = (
    field: 'raiseException' | 'repetative' | 'finishJobAtEnd'
  ) => {
    setCurrentShift({
      ...currentShift,
      [field]: !(currentShift?.[field] ?? true),
    });
  };

  const changeIntoHourMin = (time: Dayjs) => {
    const formattedTime = dayjs(time).format('HH:mm');
    return formattedTime;
  };

  const handleTimeChange = (time: Dayjs | null, field: string) => {
    if (time) {
      if (field === 'startTime') {
        setStartTime(time);
        setCurrentShift({
          ...currentShift,
          startTime: changeIntoHourMin(time),
        });
      } else {
        setEndTime(time);
        setCurrentShift({
          ...currentShift,
          endTime: changeIntoHourMin(time),
        });
      }
    }
  };

  const renderLabel = () => {
    if (shift?.id) {
      return 'Update';
    }
    return 'Add';
  };

  useEffect(() => {
    setCurrentShift(shift);
  }, []);

  return (
    <Card
      justifyContent="start"
      alignItems="center"
      gap={spacing.xxl}
      padding={spacing.xxxl}
    >
      <TextField
        label="Name"
        value={currentShift?.name}
        variant="outlined"
        onChange={(event) => handleFieldChange(event, 'name')}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TimePicker
          value={startTime}
          label="Start Time"
          inputVariant="outlined"
          onChange={(startTime) => handleTimeChange(startTime, 'startTime')}
        />
      </MuiPickersUtilsProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TimePicker
          value={endTime}
          label="End Time"
          inputVariant="outlined"
          onChange={(endTime) => handleTimeChange(endTime, 'endTime')}
        />
      </MuiPickersUtilsProvider>
      <FormControlLabel
        control={
          <Switch
            onChange={() => handleRadioChange('raiseException')}
            name="checkedB"
            color="primary"
            checked={currentShift?.raiseException}
          />
        }
        label="Raise Exception if late"
        labelPlacement="start"
      />
      <FormControlLabel
        control={
          <Switch
            onChange={() => handleRadioChange('repetative')}
            name="checkedB"
            color="primary"
            checked={currentShift?.repetative}
          />
        }
        label="Repeat the jobs"
        labelPlacement="start"
      />
      <FormControlLabel
        control={
          <Switch
            onChange={() => handleRadioChange('finishJobAtEnd')}
            name="checkedB"
            color="primary"
            checked={currentShift?.finishJobAtEnd}
          />
        }
        label="Finish Job at end"
        labelPlacement="start"
      />
      <FlexContainer flexDirection="row" justifyContent="space-between">
        <Button onClick={handleNegativeAction}>Cancel</Button>
        <PrimaryButton onClick={() => handlePositiveAction(currentShift)}>
          {isLoading ? (
            <CircularLoader size="1rem" />
          ) : (
            <Text color={colors.white}>{renderLabel()}</Text>
          )}
        </PrimaryButton>
      </FlexContainer>
    </Card>
  );
}
