import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  TextField,
  Modal,
  IconButton,
  Button,
  InputAdornment,
  Dialog,
} from '@material-ui/core';

import styled from 'styled-components';
import AddIcon from 'mdi-react/AddIcon';
import DelIcon from 'mdi-react/DeleteIcon';
import PencilIcon from 'mdi-react/PencilIcon';

import {
  FlexContainer,
  Text,
  Grid,
  Card,
  Image,
  CircularLoader,
} from '../../generics/styled';
import { Crew } from '../../types/crews';
import { colors } from '../../utils/colors';
import { formatDate } from '../../utils/macros';
import { spacing, width } from '../../utils/spacing';
import { getCompanyDetails } from '../../api/customers';
import { AuthContext } from '../../contexts/authContext';
import DetailTile from '../reusableComponents/DetailTile';
import PrimaryButton from '../reusableComponents/PrimaryButton';
import FloatingActionButton from '../reusableComponents/FloatingActionButton';
import { getSubCrews, createOrUpdateCrew, deleteSubCrew } from '../../api/crew';

const useStyles = makeStyles({
  root: {
    background: `${colors.background}`,
    padding: `${spacing.xxl} ${spacing.xl}`,
  },
  modal: {
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grid: {
    margin: `${spacing.xxl} 0`,
  },
});

function SubCrews() {
  const defaultCrewData = {
    name: '',
    email: '',
    address: '',
    phoneNumber: '',
    crewId: '',
    uid: '',
  };
  const classes = useStyles();
  const [data, setData] = useState<Array<object>>([]);
  const [modal, showModal] = useState<boolean>(false);
  const [currentAction, setCurrentAction] = useState<string>('');
  const [crew, setCrew] = useState(defaultCrewData);
  const [isMakingRequest, setIsMakingRequest] = useState(false);
  const [company, setCompany] = useState<any>(null);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const auth: any = useContext(AuthContext);

  const { authenticatedUser } = auth;

  const getData = async () => {
    const key = auth.authenticatedUser.companyKey;
    const data = await getSubCrews(key);
    setData(data);
  };

  const mapData = (subCrew: any) => {
    setCrew({ ...subCrew });
  };

  const handleInputChange = (field: string) => (event: any) => {
    setCrew({ ...crew, [field]: event.target.value });
  };

  const handleModelOpen = () => {
    setIsMakingRequest(false);
    setCrew(defaultCrewData);
    showModal(true);
  };

  const handleModalClose = () => {
    showModal(false);
  };

  useEffect(() => {
    getData();
    getCompanyDetails(auth.authenticatedUser.companyKey).then((res: any) => {
      setCompany(res);
    });
  }, [auth]);

  const getCrewId = () => {
    if (crew.uid) {
      return crew.crewId;
    }
    return `${getCompanyCode()}${crew.crewId}`;
  };

  const handleAddOrUpdateInUI = (updatedCrew: Crew, uid?: string) => {
    if (uid !== '') {
      const newCrew = { ...updatedCrew, uid };
      setData([...data, newCrew]);
    } else {
      const oldCrews = [...data];
      const crewIndex = oldCrews.findIndex(
        (crew: any) => crew?.uid === updatedCrew?.uid
      );
      oldCrews[crewIndex] = updatedCrew;
      setData(oldCrews);
    }
  };

  const handleCreateCrew = async (event: any) => {
    event.preventDefault();
    setIsMakingRequest(true);
    const data = {
      ...crew,
      masterUid: authenticatedUser.uid,
      crewId: getCrewId(),
      crewType: 'SubCrew',
      companyKey: authenticatedUser.companyKey,
    };

    try {
      const uid: string = await createOrUpdateCrew(data);
      handleAddOrUpdateInUI(data, uid);
    } catch (error) {
      console.error({ error });
    }
    setIsMakingRequest(false);
    handleModalClose();
  };

  const getCompanyCode = () => {
    return company?.companyCode;
  };

  const getCrewIdWithoutCode = () => {
    const companyCode = getCompanyCode();
    const id = crew.crewId?.split(companyCode);
    return id[id?.length - 1];
  };

  const handleDeleteButton = async () => {
    try {
      const uid = crew.uid;
      await deleteSubCrew(uid);
      const updatedCrews = data.filter(
        (currentCrew: any) => currentCrew.uid !== uid
      );
      setData(updatedCrews);
    } catch (error) {}
    setIsAlertModalOpen(false);
  };

  const handleDeleteIcon = (selectedSubCrew: any) => {
    setCrew(selectedSubCrew);
    setIsAlertModalOpen(true);
  };

  const handleMandatorySection = () => {
    if (crew?.crewId || crew?.email) return false;
    return true;
  };

  return (
    <div className={classes.root}>
      <Grid
        placeItems="start center"
        gridTemplate="auto / repeat(auto-fill, minmax(440px, 1fr))"
        gridGap={`${spacing.l} 0`}
      >
        {data?.map((subcrew: any) => (
          <Card
            key={subcrew.uid}
            width={`${width.l}`}
            padding={`${spacing.xl}`}
          >
            <Image src={subcrew?.imgUrl} width="100%" height={200} />
            <Typography variant="h5" align="center" noWrap>
              {subcrew.name}
            </Typography>
            <DetailTile title="Phone " content={subcrew?.phoneNumber || '-'} />
            <DetailTile title="Crew Id " content={subcrew?.crewId || '-'} />
            <DetailTile
              title="Logged In"
              contentColor={`${colors.stratosfyColor}`}
              content={subcrew?.loggedIn ? 'True' : 'False'}
            />
            <DetailTile
              title="Last Log In"
              content={
                subcrew?.lastSignIn ? formatDate(subcrew.lastSignIn) : '-'
              }
            />
            <FlexContainer
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                onClick={() => {
                  setCurrentAction('Save');
                  mapData(subcrew);
                  showModal(true);
                }}
              >
                <PencilIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteIcon(subcrew)}>
                <DelIcon />
              </IconButton>
            </FlexContainer>
          </Card>
        ))}
      </Grid>
      <FloatingActionButton
        onclick={() => {
          setCurrentAction('Create Crew');
          handleModelOpen();
        }}
      >
        <AddIcon />
        Create Crew
      </FloatingActionButton>

      <Modal open={modal} className={classes.modal}>
        <Card
          justifyContent="center"
          alignItems="center"
          gap={`${spacing.xl}`}
          padding={`${spacing.xxxl}`}
        >
          <CreateForm onSubmit={handleCreateCrew}>
            <TextField
              variant="outlined"
              value={crew.name}
              onChange={handleInputChange('name')}
              fullWidth
              label="Name"
              required
            />
            <TextField
              variant="outlined"
              value={crew.address}
              onChange={handleInputChange('address')}
              fullWidth
              label="Address"
              required
            />
            {!crew.uid && (
              <TextField
                variant="outlined"
                value={crew.email}
                onChange={handleInputChange('email')}
                fullWidth
                label="Email"
                required={handleMandatorySection()}
              />
            )}
            {!crew.uid ? (
              <TextField
                variant="outlined"
                value={getCrewIdWithoutCode()}
                onChange={handleInputChange('crewId')}
                fullWidth
                label="Crew ID"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCompanyCode()}
                    </InputAdornment>
                  ),
                }}
                required={handleMandatorySection()}
              />
            ) : null}
            <TextField
              variant="outlined"
              value={crew.phoneNumber}
              onChange={handleInputChange('phoneNumber')}
              fullWidth
              label="Phone number"
              required
              type="number"
              inputProps={{
                maxLength: 10,
              }}
            />

            <FlexContainer flexDirection="row" justifyContent="center">
              <Button onClick={handleModalClose}>Cancel</Button>
              <PrimaryButton onClick={() => {}} type="submit">
                {isMakingRequest ? (
                  <CircularLoader size={20} />
                ) : (
                  <Text color={colors.white}>{currentAction}</Text>
                )}
              </PrimaryButton>
            </FlexContainer>
          </CreateForm>
        </Card>
      </Modal>
      <Dialog
        open={isAlertModalOpen}
        style={{
          padding: 32,
        }}
      >
        <FlexContainer>
          <h5>Are you sure you want to delete?</h5>
          <FlexContainer>
            <Button>Cancel</Button>
            <PrimaryButton onClick={handleDeleteButton}>Delete</PrimaryButton>
          </FlexContainer>
        </FlexContainer>
      </Dialog>
    </div>
  );
}

export default SubCrews;

const CreateForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${spacing.l};
  width: 100%;
`;
