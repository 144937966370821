import React, { useContext, useEffect } from "react";

import { checkIfFirebaseAuth } from "../api/auth";
import { AuthContext } from "../contexts/authContext";

import GuestRoutes from "./GuestRoutes";
import PrivateRoutes from "./PrivateRoutes";

const Routes = () => {
  const auth = useContext(AuthContext);

  const { authenticatedUser, setAuthenticatedUser } = auth;

  useEffect(() => {
    checkIfFirebaseAuth(setAuthenticatedUser);
  }, [setAuthenticatedUser]);

  if (authenticatedUser?.crewType === null) {
    return <span>loading.....</span>;
  }

  if (authenticatedUser?.crewType !== "Org") {
    return <GuestRoutes />;
  }

  return <PrivateRoutes />;
};

export default Routes;
