import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  // useContext,
  useState,
} from 'react';

import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { Button, TextField } from '@material-ui/core';

import { logo } from '../utils/misc';
import { colors } from '../utils/colors';
import { spacing } from '../utils/spacing';
import SnackBar from './reusableComponents/SnackBar';
import { logInWithEmailAndPassword } from '../api/auth';
// import { AuthContext } from '../contexts/authContext';
// import PrimaryButton from './reusableComponents/PrimaryButton';
import {
  Card,
  CircularLoader,
  FlexContainer,
  Image,
  Text,
} from '../generics/styled';
import { errors } from '../utils/errors';

//some styling
const useStyles = makeStyles({
  input: {},
  button: {
    width: '12em',
    backgroundImage: `linear-gradient(to right, ${colors.stratosfyColor},${colors.darkblue})`,
  },
  textButton: {
    color: `${colors.stratosfyColor}`,
  },
  divider: {
    backgroundColor: `${colors.stratosfyColor}`,
    width: '100%',
  },
});

const Login: FunctionComponent = () => {
  const classes = useStyles();
  // const [openDialog, setOpenDialog] = useState(false);
  // const [logInId, setLogInId] = useState('');

  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [isLogginIn, setIsLoggingIn] = useState({
    byEmail: false,
    byCrewId: false,
  });
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // const auth = useContext(AuthContext);

  // const { setAuthenticatedUser, authenticatedUser } = auth;

  const submitDetails = async (
    event: FormEvent<HTMLFormElement | HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsLoggingIn({ ...isLogginIn, byEmail: true });
    const params = { email, password: pass };
    try {
      const user: any = await logInWithEmailAndPassword(params);
      if (user?.crewType !== 'Org') {
        setErrorMessage('User not permitted!');
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.log(error);
      switch (error.code) {
        case 'auth/user-not-found':
          setErrorMessage(error.message);
          handleSnackbarToggle();
          break;
        case 'auth/wrong-password':
          setErrorMessage(error.message);
          handleSnackbarToggle();
          break;
        default:
          break;
      }
    }
    setIsLoggingIn({ ...isLogginIn, byCrewId: false });
  };

  function handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  function handlePassChange(event: ChangeEvent<HTMLInputElement>) {
    setPass(event.target.value);
  }

  function handleSnackbarToggle() {
    setOpenSnackbar(!openSnackbar);
  }

  // function handleLogInWithId() {
  //   setOpenDialog(true);
  // }

  // async function handleLogin(
  //   event: FormEvent<HTMLButtonElement | HTMLFormElement>
  // ) {
  //   event.preventDefault();
  //   try {
  //     if (openDialog) {
  //       const response: any = await logInWithId(logInId);
  //       const { data } = response;
  //       if (!data.success) {
  //         alert('User not found.');
  //         setAuthenticatedUser({});
  //         return;
  //       }
  //       await loginUser(data);
  //     }
  //   } catch (error) {
  //     console.error('@@Error', error);
  //   }
  // }

  // function handleCrewIdChange(event: ChangeEvent<HTMLInputElement>) {
  //   setLogInId(event.target.value);
  // }

  // function handleCancel() {
  //   setOpenDialog(false);
  // }

  return (
    <>
      <Card justifyContent="center" alignItems="center">
        <Image src={logo} height={115} width={230} alt="logo" />
        <FlexContainer justifyContent="center">
          <Text fontSize="24px" color={colors.stratosfyColor}>
            Login
          </Text>
        </FlexContainer>
        <LogInForm onSubmit={submitDetails}>
          <TextField
            variant="outlined"
            label="Email"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            required
            type="email"
          />
          <TextField
            variant="outlined"
            type="password"
            label="Password"
            fullWidth
            value={pass}
            onChange={handlePassChange}
            required
          />
          <Button
            variant="contained"
            color="primary"
            onSubmit={submitDetails}
            size="large"
            disableElevation
            className={classes.button}
            type="submit"
            disabled={isLogginIn.byEmail}
          >
            {isLogginIn.byEmail ? (
              <CircularLoader size={20} />
            ) : (
              <span>Log in</span>
            )}
          </Button>
          {/* <Divider className={classes.divider} /> */}
          {/* <Button onClick={handleLogInWithId} className={classes.textButton}>
            Login with id
          </Button> */}
        </LogInForm>
        <SnackBar
          open={openSnackbar}
          handleToggle={handleSnackbarToggle}
          message={errorMessage}
          severity="error"
        />
      </Card>

      {/* <Dialog open={openDialog}>
        <LoginWithIdContainer gap="12px">
          <LogInForm onSubmit={handleLogin}>
            <TextField
              label="Crew Id"
              placeholder="ABC123"
              variant="outlined"
              onChange={handleCrewIdChange}
              required
            />
            <FlexContainer flexDirection="row" justifyContent="flex-end">
              <Button onClick={handleCancel}>Cancel</Button>
              <PrimaryButton type="submit" onClick={handleLogin}>
                {isLogginIn.byCrewId ? (
                  <CircularLoader />
                ) : (
                  <LoginText color={colors.white}>Log In</LoginText>
                )}
              </PrimaryButton>
            </FlexContainer>
          </LogInForm>
        </LoginWithIdContainer>
      </Dialog> */}
    </>
  );
};

export default Login;

// const LoginWithIdContainer = styled(FlexContainer)`
//   padding: ${spacing.l};
// `;

// const LoginText = styled(Text)`
//   margin: auto;
// `;

const LogInForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${spacing.l};
  width: 100%;
  align-items: center;
`;
