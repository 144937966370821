import { auth, firestore, functions } from '.';

const crewCollection = firestore.collection('providers');
const providersCollection = firestore.collection('providers');

export async function getCrewById(uid: string) {
  const res = await crewCollection.doc(uid).get();
  const crew = res.data() ?? {};
  return crew;
}

export function updateUserTokentoken(token: string) {
  const uid = auth.currentUser?.uid;
  return crewCollection.doc(uid).update({ token });
}

export async function getSubCrews(companyKey: string) {
  const snapshot = await providersCollection
    .where('companyKey', '==', companyKey)
    .where('masterUid', '==', auth.currentUser?.uid)
    .where('crewType', '==', 'SubCrew')
    .get();
  const crews = snapshot.docs.map((doc: any) => doc.data());
  return crews;
}

export const createOrUpdateCrew = async (crewData: any) => {
  const crewCreation = functions.httpsCallable(
    'providersGroup-providersGroup-createServiceProviderFromOrgCrew'
  );

  if (!crewData.uid) {
    const resp = await crewCreation(crewData);
    const { uid } = resp.data.data;
    return uid;
  }
  await crewCollection.doc(crewData.uid).update({ ...crewData });
  return '';
};

export const updateCrewDetails = (crewData: any) => {
  return crewCollection.doc(crewData.uid).update({ ...crewData });
};

export const deleteSubCrew = (crewUid: any) => {
  return crewCollection.doc(crewUid).delete();
};
