import React from 'react';
import { FlexContainer, Text } from '../../generics/styled';

interface DetailProps {
  title: string;
  content: string;
  justifyContent?: string;
  titleColor?: string;
  contentColor?: string;
  titleSize?: string;
  contentSize?: string;
}

function DetailTile(props: DetailProps) {
  const {
    title,
    titleColor,
    content,
    contentColor,
    justifyContent,
    titleSize,
    contentSize,
  } = props;

  const overflow = content.length < 30;

  return (
    <FlexContainer
      flexDirection={overflow ? 'row' : 'column'}
      justifyContent={justifyContent ?? 'space-between'}
    >
      <Text color={titleColor} fontSize={titleSize} textAlign="start">
        {title}
      </Text>
      <Text
        textAlign="end"
        color={contentColor}
        fontSize={contentSize}
        fontWeight="bold"
      >
        {content}
      </Text>
    </FlexContainer>
  );
}

export default DetailTile;
