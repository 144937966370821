export const spacing = {
    sm: "4px",
    m: "8px",
    l: "12px",
    xl: "16px",
    xxl: "24px",
    xxxl: "48px",
    xxxxl: "80px",
};

export const width = {
    sm: "15em",
    m: "20em",
    l: "25em",
    xl: "30em",
    x2l: "35em",
    x3l: "40em",
    x4l: "45em",
    x5l: "50em",
};
