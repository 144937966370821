import React, { useEffect, useContext, useState } from 'react';

import styled from 'styled-components';
import AddIcon from 'mdi-react/AddIcon';
import DelIcon from 'mdi-react/DeleteIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import { IconButton } from '@material-ui/core';

import { Shift } from '../../types/shifts';
import { colors } from '../../utils/colors';
import { spacing, width } from '../../utils/spacing';
import { AuthContext } from '../../contexts/authContext';
import { getShiftsByCompanyKey } from '../../api/shifts';
import { addShift, updateShift, deleteShift } from '../../api/shifts';
import FloatingActionButton from '../reusableComponents/FloatingActionButton';
import {
  Container,
  Card,
  Grid,
  Text,
  FlexContainer,
  CircularLoader,
  StyledModal,
} from '../../generics/styled';
import ShiftItem from './ShiftDialog';

const defaultShift = {
  id: '',
  active: false,
  companyKey: '',
  endTime: '',
  finishJobAtEnd: false,
  name: '',
  raiseException: false,
  repetative: false,
  startTime: '',
};

export default function Shifts() {
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedShift, setSelectedShift] = useState(defaultShift);
  const [isEditing, setEditing] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const auth: any = useContext(AuthContext);
  const companyKey = auth?.authenticatedUser?.companyKey;

  const getShifts = async () => {
    try {
      const shifts = await getShiftsByCompanyKey(companyKey);
      setShifts(shifts);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const updateShiftInUI = (shift: Shift) => {
    let oldShifts = [...shifts];

    const shiftIndex = oldShifts.findIndex(
      (currentShift) => currentShift.id === shift.id
    );
    oldShifts[shiftIndex] = shift;

    setShifts(oldShifts);
  };

  const handleShiftAddOrEdit = async (shift: Shift) => {
    setEditing(true);

    try {
      if (shift?.id) {
        await updateShift(companyKey, shift);
        updateShiftInUI(shift);
      } else {
        const updatedShift = await addShift(companyKey, shift);
        setShifts([...shifts, updatedShift]);
      }
    } catch (error) {
      console.log(error);
    }

    handleModalClose();
    setEditing(false);
  };

  const handleShiftDelete = async (shift: Shift) => {
    const shiftId = shift?.id;
    try {
      await deleteShift(companyKey, shiftId);
      const filteredShifts = shifts.filter((shift) => shift.id !== shiftId);
      setShifts(filteredShifts);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getShifts();
  }, []);

  if (isLoading) {
    return (
      <CenterLayout justifyContent="center" alignItems="center">
        <CircularLoader color="primary" />
      </CenterLayout>
    );
  }

  return (
    <>
      <Container background={colors.background} padding={spacing.xxl}>
        <Grid
          gridTemplate="auto / repeat(auto-fill, minmax(320px, 1fr))"
          gridGap={`${spacing.l} 0`}
        >
          {shifts?.map((shift: Shift) => (
            <Card width={width.sm}>
              <FlexContainer
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                gap="8px"
              >
                <Text fontSize="16px" style={{ fontWeight: 'bold' }}>
                  {shift?.name}
                </Text>
                {shift?.startTime} - {shift?.endTime}
              </FlexContainer>
              <FlexContainer
                justifyContent="center"
                alignItems="center"
                flexDirection="row"
              >
                <IconButton
                  onClick={() => {
                    setOpenModal(true);
                    setSelectedShift(shift);
                  }}
                >
                  <PencilIcon />
                </IconButton>
                <IconButton onClick={() => handleShiftDelete(shift)}>
                  <DelIcon />
                </IconButton>
              </FlexContainer>
            </Card>
          ))}
        </Grid>
        <FlexContainer justifyContent="center" alignItems="center">
          <StyledModal open={openModal}>
            <ShiftItem
              shift={selectedShift}
              isLoading={isEditing}
              handleNegativeAction={handleModalClose}
              handlePositiveAction={(editedShift: Shift) =>
                handleShiftAddOrEdit(editedShift)
              }
            />
          </StyledModal>
        </FlexContainer>
      </Container>
      <FloatingActionButton
        onclick={() => {
          setSelectedShift(defaultShift);
          setOpenModal(true);
        }}
      >
        <AddIcon />
        Create Shift
      </FloatingActionButton>
    </>
  );
}

const CenterLayout = styled(FlexContainer)`
  background: ${colors.background};
`;
